import { ClickAwayListener } from '@mui/material';
import MaterialTooltip from '@mui/material/Tooltip';
import React from 'react'
import InfoIcon from '@mui/icons-material/Info';

export const Tooltip: React.FC<{text: string}> = ({text}) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <MaterialTooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={text}
        >
          <InfoIcon onClick={handleTooltipOpen} />
        </MaterialTooltip>
      </div>
    </ClickAwayListener>
  )
}