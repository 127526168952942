import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_PUBLIC_URL,
});

const authLink = setContext(async (_, { headers }) => {
  // const user = await Auth.currentAuthenticatedUser();
  // const userSession = user.getSignInUserSession();
  // const token = userSession?.getIdToken().getJwtToken();

  // return {
  //   headers: {
  //     ...headers,
  //     authorization: token ? `Bearer ${token}` : "",
  //   },
  // };
  return {
    headers: {
      ...headers,
    }
  }
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ addTypename: false }),
});
