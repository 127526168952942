import { Button, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useHistory, useParams } from "react-router-dom"
import { colors } from '../../styles'

interface IConfirmationParams {
  details: string
}

export const Confirmation: React.FC = () => {
  let { details } = useParams<IConfirmationParams>()
  const history = useHistory()
  const goToMainPage = () => {
    history.push('/')
  }

  if (!details) {
    return (
      <Stack spacing={2} alignItems={"center"}>
        <Typography variant="h4">There was an error after the payment was processed, please review your email that you have got a receipt for the transaction.</Typography>
      </Stack>
    )
  }
  return (
    <Grid container display={'flex'} justifyContent='center'>
      <Stack spacing={4} alignItems={"center"} width='100%'>
        <Typography variant="h4" textAlign={'center'}>Payment for {details} has been processed.</Typography>
        <Typography variant="h5" textAlign={'center'} sx={{color: colors.blue}}>If the payment was successfully processed, you will receive a receipt via email.</Typography>
        <Button sx={{ padding: 2 }} variant='contained' onClick={goToMainPage}>
          Back To Home
        </Button>
      </Stack>
    </Grid>
  )
}