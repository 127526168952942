import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type CheckIfHasTokensInput = {
  phone: Scalars['String'];
  siteId: Scalars['Int'];
};

export enum DayClass {
  Weekday = 'WEEKDAY',
  Weekend = 'WEEKEND'
}

export enum FeeType {
  Early = 'EARLY',
  Hourly = 'HOURLY',
  Monthly = 'MONTHLY',
  Overnight = 'OVERNIGHT',
  SpecialTariff = 'SPECIAL_TARIFF'
}

export type MfaTokenInput = {
  phone: Scalars['String'];
  siteId: Scalars['Int'];
  token: Scalars['String'];
};

export type Mutation = {
  checkIfHasToken: Payment;
  getPaymentLink: Payment;
  payWithToken: Payment;
  removePlate: Scalars['Boolean'];
  removeToken: Scalars['Boolean'];
  validateMfa: ValidationResponse;
};


export type MutationCheckIfHasTokenArgs = {
  hasTokensArgs: CheckIfHasTokensInput;
};


export type MutationGetPaymentLinkArgs = {
  paymentArgs: PaymentInput;
};


export type MutationPayWithTokenArgs = {
  paymentArgs: TokenPaymentInput;
};


export type MutationRemovePlateArgs = {
  plateId: Scalars['Int'];
};


export type MutationRemoveTokenArgs = {
  siteId: Scalars['String'];
  token: Scalars['String'];
};


export type MutationValidateMfaArgs = {
  mfaTokenArgs: MfaTokenInput;
};

export type Payment = {
  error?: Maybe<Scalars['String']>;
  hasTokens: Scalars['Boolean'];
  redirectUrl?: Maybe<Scalars['String']>;
};

export type PaymentInput = {
  /** Number of minutes that the permit is valid for */
  duration: Scalars['Int'];
  /** Email for contact information */
  email?: InputMaybe<Scalars['String']>;
  /** Describes what type of Fee is the user selecting */
  feeType: FeeType;
  /** User name */
  name: Scalars['String'];
  /** Phone for contact information */
  phone?: InputMaybe<Scalars['String']>;
  /** QR Code Identifier for site */
  qrCode: Scalars['String'];
  /** Flag to determine if reached max duration */
  reachedMaxDuration: Scalars['Boolean'];
  /** Flag to receive email notifications */
  receiveEmails: Scalars['Boolean'];
  /** Flag to receive sms notifications */
  receiveSms: Scalars['Boolean'];
  specialTariffPricingId?: InputMaybe<Scalars['Int']>;
  /** Flag to save payment information */
  storePayment: Scalars['Boolean'];
  /** Vehicle registration number */
  vrm: Scalars['String'];
};

export type PaymentToken = {
  cardExpiry: Scalars['String'];
  cardHolder: Scalars['String'];
  cardNumber: Scalars['String'];
  cardToken: Scalars['String'];
  cardType: Scalars['String'];
  id: Scalars['String'];
};

export type Query = {
  getSites: Array<Site>;
  validateRenewal: Renewal;
};


export type QueryValidateRenewalArgs = {
  sessionCode: Scalars['String'];
};

export type Renewal = {
  error?: Maybe<Scalars['String']>;
  renewalInfo?: Maybe<RenewalInfo>;
  successful: Scalars['Boolean'];
};

export type RenewalInfo = {
  /** Number of minutes that the permit is valid for */
  duration: Scalars['Int'];
  /** User email */
  email: Scalars['String'];
  /** User phone */
  phone: Scalars['String'];
  /** Site Id */
  siteId: Scalars['Int'];
  /** Site Name */
  siteName: Scalars['String'];
  tokens: Array<PaymentToken>;
  /** Vehicle registration number */
  vrm: Scalars['String'];
};

export type Site = {
  costPerHour: Scalars['Float'];
  costPerHourWeekend: Scalars['Float'];
  earlyBirdEnd: Scalars['String'];
  earlyBirdPrice: Scalars['Float'];
  earlyBirdStart: Scalars['String'];
  hasEarlyBird: Scalars['Boolean'];
  hasMaxTime: Scalars['Boolean'];
  hasMaxTimeWeekend: Scalars['Boolean'];
  hasMonthly: Scalars['Boolean'];
  hasOvernight: Scalars['Boolean'];
  id: Scalars['Float'];
  maxCost: Scalars['Float'];
  maxCostWeekend: Scalars['Float'];
  maxTime: Scalars['Float'];
  maxTimeWeekend: Scalars['Float'];
  minimumTime: Scalars['Float'];
  monthlyFee: Scalars['Float'];
  name: Scalars['String'];
  overnightEnd: Scalars['String'];
  overnightPrice: Scalars['Float'];
  overnightStart: Scalars['String'];
  paymarkSecret?: Maybe<Scalars['String']>;
  qrCode: Scalars['String'];
  specialTariffDomain: Scalars['String'];
  specialTariffPricing?: Maybe<Array<SpecialTariffPricing>>;
  timeStep: Scalars['Float'];
  transactionFee: Scalars['Float'];
  zatParkId: Scalars['Float'];
};

export type SpecialTariffPricing = {
  dayClass: DayClass;
  end: Scalars['String'];
  id: Scalars['Float'];
  name: Scalars['String'];
  price: Scalars['Float'];
  sessionTime?: Maybe<Scalars['Int']>;
  start: Scalars['String'];
  untilTime?: Maybe<Scalars['String']>;
};

export type TokenPaymentInput = {
  /** Number of minutes that the permit is valid for */
  duration: Scalars['Int'];
  /** Email for contact information */
  email?: InputMaybe<Scalars['String']>;
  /** Describes what type of Fee is the user selecting */
  feeType: FeeType;
  /** User name */
  name: Scalars['String'];
  /** Phone for contact information */
  phone?: InputMaybe<Scalars['String']>;
  /** QR Code Identifier for site */
  qrCode: Scalars['String'];
  /** Flag to determine if reached max duration */
  reachedMaxDuration: Scalars['Boolean'];
  /** Flag to receive email notifications */
  receiveEmails: Scalars['Boolean'];
  /** Flag to receive sms notifications */
  receiveSms: Scalars['Boolean'];
  specialTariffPricingId?: InputMaybe<Scalars['Int']>;
  /** Flag to save payment information */
  storePayment: Scalars['Boolean'];
  /** Token Identifier */
  token: Scalars['String'];
  /** Vehicle registration number */
  vrm: Scalars['String'];
};

export type UserPlate = {
  id: Scalars['Int'];
  vrm: Scalars['String'];
};

export type ValidationResponse = {
  error?: Maybe<Scalars['String']>;
  plates: Array<UserPlate>;
  successful: Scalars['Boolean'];
  tokens: Array<PaymentToken>;
};

export type CheckIfHasTokenMutationVariables = Exact<{
  phone: Scalars['String'];
  siteId: Scalars['Int'];
}>;


export type CheckIfHasTokenMutation = { checkIfHasToken: { error?: string | null, hasTokens: boolean } };

export type GetPaymentLinkMutationVariables = Exact<{
  qrCode: Scalars['String'];
  duration: Scalars['Int'];
  vrm: Scalars['String'];
  name: Scalars['String'];
  storePayment: Scalars['Boolean'];
  receiveEmails: Scalars['Boolean'];
  receiveSms: Scalars['Boolean'];
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  feeType: FeeType;
  reachedMaxDuration: Scalars['Boolean'];
  specialTariffPricingId?: InputMaybe<Scalars['Int']>;
}>;


export type GetPaymentLinkMutation = { getPaymentLink: { redirectUrl?: string | null, error?: string | null } };

export type GetSitesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSitesQuery = { getSites: Array<{ id: number, name: string, zatParkId: number, costPerHour: number, costPerHourWeekend: number, qrCode: string, monthlyFee: number, minimumTime: number, timeStep: number, hasMonthly: boolean, transactionFee: number, hasEarlyBird: boolean, earlyBirdStart: string, earlyBirdEnd: string, earlyBirdPrice: number, hasOvernight: boolean, overnightStart: string, overnightEnd: string, overnightPrice: number, specialTariffDomain: string, hasMaxTime: boolean, hasMaxTimeWeekend: boolean, maxTime: number, maxTimeWeekend: number, maxCost: number, maxCostWeekend: number, specialTariffPricing?: Array<{ id: number, price: number, start: string, end: string, name: string, dayClass: DayClass, untilTime?: string | null, sessionTime?: number | null }> | null }> };

export type PayWithTokenMutationVariables = Exact<{
  qrCode: Scalars['String'];
  duration: Scalars['Int'];
  vrm: Scalars['String'];
  name: Scalars['String'];
  storePayment: Scalars['Boolean'];
  receiveEmails: Scalars['Boolean'];
  receiveSms: Scalars['Boolean'];
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  feeType: FeeType;
  reachedMaxDuration: Scalars['Boolean'];
  specialTariffPricingId?: InputMaybe<Scalars['Int']>;
}>;


export type PayWithTokenMutation = { payWithToken: { redirectUrl?: string | null, error?: string | null } };

export type RemovePlateMutationVariables = Exact<{
  plateId: Scalars['Int'];
}>;


export type RemovePlateMutation = { removePlate: boolean };

export type RemoveTokenMutationVariables = Exact<{
  token: Scalars['String'];
  siteId: Scalars['String'];
}>;


export type RemoveTokenMutation = { removeToken: boolean };

export type ValidateMfaMutationVariables = Exact<{
  token: Scalars['String'];
  phone: Scalars['String'];
  siteId: Scalars['Int'];
}>;


export type ValidateMfaMutation = { validateMfa: { successful: boolean, error?: string | null, tokens: Array<{ id: string, cardToken: string, cardNumber: string, cardType: string, cardExpiry: string, cardHolder: string }>, plates: Array<{ id: number, vrm: string }> } };

export type ValidateRenewalQueryVariables = Exact<{
  sessionCode: Scalars['String'];
}>;


export type ValidateRenewalQuery = { validateRenewal: { successful: boolean, error?: string | null, renewalInfo?: { vrm: string, siteId: number, siteName: string, duration: number, email: string, phone: string, tokens: Array<{ id: string, cardToken: string, cardNumber: string, cardType: string, cardExpiry: string, cardHolder: string }> } | null } };


export const CheckIfHasTokenDocument = gql`
    mutation CheckIfHasToken($phone: String!, $siteId: Int!) {
  checkIfHasToken(hasTokensArgs: {phone: $phone, siteId: $siteId}) {
    error
    hasTokens
  }
}
    `;
export type CheckIfHasTokenMutationFn = Apollo.MutationFunction<CheckIfHasTokenMutation, CheckIfHasTokenMutationVariables>;

/**
 * __useCheckIfHasTokenMutation__
 *
 * To run a mutation, you first call `useCheckIfHasTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckIfHasTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkIfHasTokenMutation, { data, loading, error }] = useCheckIfHasTokenMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useCheckIfHasTokenMutation(baseOptions?: Apollo.MutationHookOptions<CheckIfHasTokenMutation, CheckIfHasTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckIfHasTokenMutation, CheckIfHasTokenMutationVariables>(CheckIfHasTokenDocument, options);
      }
export type CheckIfHasTokenMutationHookResult = ReturnType<typeof useCheckIfHasTokenMutation>;
export type CheckIfHasTokenMutationResult = Apollo.MutationResult<CheckIfHasTokenMutation>;
export type CheckIfHasTokenMutationOptions = Apollo.BaseMutationOptions<CheckIfHasTokenMutation, CheckIfHasTokenMutationVariables>;
export const GetPaymentLinkDocument = gql`
    mutation GetPaymentLink($qrCode: String!, $duration: Int!, $vrm: String!, $name: String!, $storePayment: Boolean!, $receiveEmails: Boolean!, $receiveSms: Boolean!, $email: String, $phone: String, $feeType: FeeType!, $reachedMaxDuration: Boolean!, $specialTariffPricingId: Int) {
  getPaymentLink(
    paymentArgs: {qrCode: $qrCode, duration: $duration, vrm: $vrm, name: $name, storePayment: $storePayment, receiveEmails: $receiveEmails, receiveSms: $receiveSms, email: $email, phone: $phone, feeType: $feeType, reachedMaxDuration: $reachedMaxDuration, specialTariffPricingId: $specialTariffPricingId}
  ) {
    redirectUrl
    error
  }
}
    `;
export type GetPaymentLinkMutationFn = Apollo.MutationFunction<GetPaymentLinkMutation, GetPaymentLinkMutationVariables>;

/**
 * __useGetPaymentLinkMutation__
 *
 * To run a mutation, you first call `useGetPaymentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPaymentLinkMutation, { data, loading, error }] = useGetPaymentLinkMutation({
 *   variables: {
 *      qrCode: // value for 'qrCode'
 *      duration: // value for 'duration'
 *      vrm: // value for 'vrm'
 *      name: // value for 'name'
 *      storePayment: // value for 'storePayment'
 *      receiveEmails: // value for 'receiveEmails'
 *      receiveSms: // value for 'receiveSms'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      feeType: // value for 'feeType'
 *      reachedMaxDuration: // value for 'reachedMaxDuration'
 *      specialTariffPricingId: // value for 'specialTariffPricingId'
 *   },
 * });
 */
export function useGetPaymentLinkMutation(baseOptions?: Apollo.MutationHookOptions<GetPaymentLinkMutation, GetPaymentLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetPaymentLinkMutation, GetPaymentLinkMutationVariables>(GetPaymentLinkDocument, options);
      }
export type GetPaymentLinkMutationHookResult = ReturnType<typeof useGetPaymentLinkMutation>;
export type GetPaymentLinkMutationResult = Apollo.MutationResult<GetPaymentLinkMutation>;
export type GetPaymentLinkMutationOptions = Apollo.BaseMutationOptions<GetPaymentLinkMutation, GetPaymentLinkMutationVariables>;
export const GetSitesDocument = gql`
    query GetSites {
  getSites {
    id
    name
    zatParkId
    costPerHour
    costPerHourWeekend
    qrCode
    monthlyFee
    minimumTime
    timeStep
    hasMonthly
    transactionFee
    hasEarlyBird
    earlyBirdStart
    earlyBirdEnd
    earlyBirdPrice
    hasOvernight
    overnightStart
    overnightEnd
    overnightPrice
    specialTariffDomain
    specialTariffPricing {
      id
      price
      start
      end
      name
      dayClass
      untilTime
      sessionTime
    }
    hasMaxTime
    hasMaxTimeWeekend
    maxTime
    maxTimeWeekend
    maxCost
    maxCostWeekend
  }
}
    `;

/**
 * __useGetSitesQuery__
 *
 * To run a query within a React component, call `useGetSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSitesQuery(baseOptions?: Apollo.QueryHookOptions<GetSitesQuery, GetSitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSitesQuery, GetSitesQueryVariables>(GetSitesDocument, options);
      }
export function useGetSitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSitesQuery, GetSitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSitesQuery, GetSitesQueryVariables>(GetSitesDocument, options);
        }
export type GetSitesQueryHookResult = ReturnType<typeof useGetSitesQuery>;
export type GetSitesLazyQueryHookResult = ReturnType<typeof useGetSitesLazyQuery>;
export type GetSitesQueryResult = Apollo.QueryResult<GetSitesQuery, GetSitesQueryVariables>;
export function refetchGetSitesQuery(variables?: GetSitesQueryVariables) {
      return { query: GetSitesDocument, variables: variables }
    }
export const PayWithTokenDocument = gql`
    mutation PayWithToken($qrCode: String!, $duration: Int!, $vrm: String!, $name: String!, $storePayment: Boolean!, $receiveEmails: Boolean!, $receiveSms: Boolean!, $email: String, $phone: String, $token: String!, $feeType: FeeType!, $reachedMaxDuration: Boolean!, $specialTariffPricingId: Int) {
  payWithToken(
    paymentArgs: {qrCode: $qrCode, duration: $duration, vrm: $vrm, name: $name, storePayment: $storePayment, receiveEmails: $receiveEmails, receiveSms: $receiveSms, email: $email, phone: $phone, token: $token, feeType: $feeType, reachedMaxDuration: $reachedMaxDuration, specialTariffPricingId: $specialTariffPricingId}
  ) {
    redirectUrl
    error
  }
}
    `;
export type PayWithTokenMutationFn = Apollo.MutationFunction<PayWithTokenMutation, PayWithTokenMutationVariables>;

/**
 * __usePayWithTokenMutation__
 *
 * To run a mutation, you first call `usePayWithTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayWithTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payWithTokenMutation, { data, loading, error }] = usePayWithTokenMutation({
 *   variables: {
 *      qrCode: // value for 'qrCode'
 *      duration: // value for 'duration'
 *      vrm: // value for 'vrm'
 *      name: // value for 'name'
 *      storePayment: // value for 'storePayment'
 *      receiveEmails: // value for 'receiveEmails'
 *      receiveSms: // value for 'receiveSms'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      token: // value for 'token'
 *      feeType: // value for 'feeType'
 *      reachedMaxDuration: // value for 'reachedMaxDuration'
 *      specialTariffPricingId: // value for 'specialTariffPricingId'
 *   },
 * });
 */
export function usePayWithTokenMutation(baseOptions?: Apollo.MutationHookOptions<PayWithTokenMutation, PayWithTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PayWithTokenMutation, PayWithTokenMutationVariables>(PayWithTokenDocument, options);
      }
export type PayWithTokenMutationHookResult = ReturnType<typeof usePayWithTokenMutation>;
export type PayWithTokenMutationResult = Apollo.MutationResult<PayWithTokenMutation>;
export type PayWithTokenMutationOptions = Apollo.BaseMutationOptions<PayWithTokenMutation, PayWithTokenMutationVariables>;
export const RemovePlateDocument = gql`
    mutation RemovePlate($plateId: Int!) {
  removePlate(plateId: $plateId)
}
    `;
export type RemovePlateMutationFn = Apollo.MutationFunction<RemovePlateMutation, RemovePlateMutationVariables>;

/**
 * __useRemovePlateMutation__
 *
 * To run a mutation, you first call `useRemovePlateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePlateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePlateMutation, { data, loading, error }] = useRemovePlateMutation({
 *   variables: {
 *      plateId: // value for 'plateId'
 *   },
 * });
 */
export function useRemovePlateMutation(baseOptions?: Apollo.MutationHookOptions<RemovePlateMutation, RemovePlateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePlateMutation, RemovePlateMutationVariables>(RemovePlateDocument, options);
      }
export type RemovePlateMutationHookResult = ReturnType<typeof useRemovePlateMutation>;
export type RemovePlateMutationResult = Apollo.MutationResult<RemovePlateMutation>;
export type RemovePlateMutationOptions = Apollo.BaseMutationOptions<RemovePlateMutation, RemovePlateMutationVariables>;
export const RemoveTokenDocument = gql`
    mutation RemoveToken($token: String!, $siteId: String!) {
  removeToken(token: $token, siteId: $siteId)
}
    `;
export type RemoveTokenMutationFn = Apollo.MutationFunction<RemoveTokenMutation, RemoveTokenMutationVariables>;

/**
 * __useRemoveTokenMutation__
 *
 * To run a mutation, you first call `useRemoveTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTokenMutation, { data, loading, error }] = useRemoveTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useRemoveTokenMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTokenMutation, RemoveTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTokenMutation, RemoveTokenMutationVariables>(RemoveTokenDocument, options);
      }
export type RemoveTokenMutationHookResult = ReturnType<typeof useRemoveTokenMutation>;
export type RemoveTokenMutationResult = Apollo.MutationResult<RemoveTokenMutation>;
export type RemoveTokenMutationOptions = Apollo.BaseMutationOptions<RemoveTokenMutation, RemoveTokenMutationVariables>;
export const ValidateMfaDocument = gql`
    mutation ValidateMfa($token: String!, $phone: String!, $siteId: Int!) {
  validateMfa(mfaTokenArgs: {token: $token, phone: $phone, siteId: $siteId}) {
    tokens {
      id
      cardToken
      cardNumber
      cardType
      cardExpiry
      cardHolder
    }
    successful
    error
    plates {
      id
      vrm
    }
  }
}
    `;
export type ValidateMfaMutationFn = Apollo.MutationFunction<ValidateMfaMutation, ValidateMfaMutationVariables>;

/**
 * __useValidateMfaMutation__
 *
 * To run a mutation, you first call `useValidateMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateMfaMutation, { data, loading, error }] = useValidateMfaMutation({
 *   variables: {
 *      token: // value for 'token'
 *      phone: // value for 'phone'
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useValidateMfaMutation(baseOptions?: Apollo.MutationHookOptions<ValidateMfaMutation, ValidateMfaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateMfaMutation, ValidateMfaMutationVariables>(ValidateMfaDocument, options);
      }
export type ValidateMfaMutationHookResult = ReturnType<typeof useValidateMfaMutation>;
export type ValidateMfaMutationResult = Apollo.MutationResult<ValidateMfaMutation>;
export type ValidateMfaMutationOptions = Apollo.BaseMutationOptions<ValidateMfaMutation, ValidateMfaMutationVariables>;
export const ValidateRenewalDocument = gql`
    query ValidateRenewal($sessionCode: String!) {
  validateRenewal(sessionCode: $sessionCode) {
    renewalInfo {
      vrm
      siteId
      siteName
      duration
      email
      phone
      tokens {
        id
        cardToken
        cardNumber
        cardType
        cardExpiry
        cardHolder
      }
    }
    successful
    error
  }
}
    `;

/**
 * __useValidateRenewalQuery__
 *
 * To run a query within a React component, call `useValidateRenewalQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateRenewalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateRenewalQuery({
 *   variables: {
 *      sessionCode: // value for 'sessionCode'
 *   },
 * });
 */
export function useValidateRenewalQuery(baseOptions: Apollo.QueryHookOptions<ValidateRenewalQuery, ValidateRenewalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateRenewalQuery, ValidateRenewalQueryVariables>(ValidateRenewalDocument, options);
      }
export function useValidateRenewalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateRenewalQuery, ValidateRenewalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateRenewalQuery, ValidateRenewalQueryVariables>(ValidateRenewalDocument, options);
        }
export type ValidateRenewalQueryHookResult = ReturnType<typeof useValidateRenewalQuery>;
export type ValidateRenewalLazyQueryHookResult = ReturnType<typeof useValidateRenewalLazyQuery>;
export type ValidateRenewalQueryResult = Apollo.QueryResult<ValidateRenewalQuery, ValidateRenewalQueryVariables>;
export function refetchValidateRenewalQuery(variables: ValidateRenewalQueryVariables) {
      return { query: ValidateRenewalDocument, variables: variables }
    }