import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import logo from './CentralPark_Logo_Long.png'
import { Route, Switch } from 'react-router-dom';
import { Home } from './modules/Home';
import { Confirmation } from './modules/Confirmation';
import { Failed } from './modules/Failed';
import { NoMatch } from './modules/NoMatch';

function App() {
  return (
    <Stack width={'100%'} alignItems='center'>
      <Stack spacing={2} alignItems='center' padding={2} sx={{maxWidth: 500}}>
      <img src={logo} alt="Logo" width={'100%'} />
      <Typography variant='h4'>Pay Parking</Typography>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/site/:qrCode">
          <Home />
        </Route>
        <Route exact path="/renew/:sessionCode">
          <Home />
        </Route>
        <Route exact path="/confirmation/:details">
          <Confirmation />
        </Route>
        <Route exact path="/failed">
          <Failed />
        </Route>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </Stack>
    </Stack>
  );
}

export default App;
