import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { colors } from './styles';
import { client } from './apollo/client';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = createTheme({
  palette: {
  primary: {
      main: colors.orange,
    }
  },
  typography: {
    fontFamily: 'Poppins',
    allVariants: {
      color: colors.orange,
    },
    body1: {
      '&.MuiFormControlLabel-label': {
        color: colors.blue,
      },
    }
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          color: colors.blue,
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        InputProps: {
          sx: {
            color: colors.blue,
          }
        }
      },
      styleOverrides: {
        root: {
          "& .MuiFormLabel-root": {
            color: colors.orange,
          },
          "& .MuiFormLabel-root.Mui-focused": {
            color: colors.orange,
          },
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: colors.orange,
              '& legend': {
                color: colors.orange
              }
            },
            '&.Mui-focused fieldset': {
              borderColor: colors.orange,
              '& legend': {
                color: colors.orange
              }
            },
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderColor: colors.orange,
          color: colors.blue,
          fontSize: '2.5rem',
          '&:focus': {
            borderColor: colors.orange,
          },
        },
        contained: {
          backgroundColor: colors.orange,
          borderColor: colors.orange,
          fontSize: '2rem',
          color: colors.white,
          ":hover": {
            backgroundColor: colors.orange,
            color: colors.white,
          }
        },
        // focusVisible: {
        //   borderColor: colors.orange,
        // }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            fill: colors.orange
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: colors.blue,
        }
      }
    }
  }
})

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
