import { Button, Stack, Typography } from "@mui/material"
import { useHistory } from "react-router-dom"
import { useQueryParams } from "../../shared/useQueryParams"

export const Failed: React.FC = () => {
  const history = useHistory()
  const query = useQueryParams()
  const errorMessage = query.get('errorMessage')
  
  const goToMainPage = () => {
    history.push('/')
  }

  return (
    <Stack spacing={2} alignItems={"center"}>
      <Typography variant="h4">There was an error processing your payment, please try again or contact support.</Typography>
      {
        errorMessage && <Typography variant="h5" fontWeight='bold'>Reason: {errorMessage}</Typography>
      }
      <Button variant='contained' onClick={goToMainPage}>
        Back to Home
      </Button>
    </Stack>
  )
}