import humanize from 'humanize-duration'

export const shortEnglishHumanizer = humanize.humanizer({
  language: "shortEn",
  languages: {
    shortEn: {
      h: (count) => count === 1 ? "hour" : "hours",
      m: () => "mins",
    }
  }
});