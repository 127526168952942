import React from 'react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Stack, Typography } from '@mui/material';

export const NoMatch: React.FC = () => {
  return (
    <Stack spacing={2} alignItems='center'>
      <WarningAmberIcon sx={{
        fontSize: 70,
      }} />
      <Typography variant="h4" textAlign={'center'}>Oops, something went wrong.</Typography>
    </Stack>
  )
}